import moment from 'moment'
import i18n from '@/i18n'

export default (dateString: string): string | number => {
  const date = moment(dateString)

  const now = moment()

  if (now.diff(date, 'months')) {
    return moment(String(date)).locale(i18n.locale).format('YYYY.MM.DD')
  }

  return moment(date).locale(i18n.locale).fromNow()
}
